import React, { useState, useEffect } from "react";
import {
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Api from "../../network/Api";
import User from "../../types/User";
import Workspace from "../../types/Workspace";
import FormUtils from "../../utils/FormUtils";
import OnboardingLayout from "./Layout";

export type OnboardingProps = {
  user: User;
};

type WorkspaceOnboardingParams = {
  name?: string;
  domain_whitelist: "true" | "false";
  industry?: string;
  description?: string;
  is_ready: "true" | "false";
};

const WorkspaceOnboarding = (props: OnboardingProps) => {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [params, setParams] = useState<WorkspaceOnboardingParams>({
    domain_whitelist: "true",
    is_ready: "true",
  });
  const [isLoading, shouldLoad] = useState<boolean>(false);

  useEffect(() => {
    Api.get(`/workspace`).then(setWorkspace);
  }, []);

  return (
    <OnboardingLayout title="Your workspace is almost ready">
      <>
        {workspace && (
          <form
            method="put"
            action="/workspace"
            onSubmit={(e) => {
              FormUtils.onFormSubmit(e);
              shouldLoad(true);
            }}
          >
            <FormGroup>
              <TextField
                name="name"
                label="Name of workspace"
                defaultValue={workspace.name}
                variant="outlined"
                required
                onChange={(e) => {
                  setParams({ ...params, name: e.target.value });
                }}
              />
            </FormGroup>
            {props.user.domain && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setParams({
                          ...params,
                          domain_whitelist: e.target.value,
                        });
                      }}
                      defaultChecked={params.domain_whitelist == "true"}
                    />
                  }
                  label={`Let anyone with an @${props.user.domain} email join this workspace.`}
                />
              </FormGroup>
            )}
            <input
              type="hidden"
              name="domain_whitelist"
              value={params.domain_whitelist}
            />
            <FormGroup>
              <p>
                Tell us more more about your business. This will help getting
                better results. You can skip this step if you want and come back
                later.
              </p>
            </FormGroup>
            <FormGroup>
              <TextField
                name="industry"
                label="Industry"
                defaultValue={workspace.industry}
                variant="outlined"
                required
                onChange={(e) => {
                  setParams({ ...params, name: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                name="description"
                label="Main purpose of business"
                defaultValue={workspace?.description}
                variant="outlined"
                required
                onChange={(e) => {
                  setParams({ ...params, name: e.target.value });
                }}
              />
            </FormGroup>
            <input type="hidden" name="is_ready" value={params.is_ready} />
            <LoadingButton loading={isLoading} variant="outlined" type="submit">
              Save
            </LoadingButton>
          </form>
        )}
      </>
    </OnboardingLayout>
  );
};

export default WorkspaceOnboarding;
