import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import DropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UserMenu from './UserMenu';
import Avatar from './Avatar';
import Logo from './Logo';
// import LevelProgress from './LevelProgress';
import { LayoutProps } from '../../types/View';
import { AppProps } from '../App';

const UserNav = (props: LayoutProps & AppProps) => {

  const [highlightsInviteButton, shouldHighlightInviteButton] = useState(false);
  const [showsUserMenu, setShowsUserMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  useEffect(() => {
    window.setTimeout(() => {
      shouldHighlightInviteButton(true);
    }, 10000)
  }, []);

  const toggleMenu = (event) => {
    setShowsUserMenu(!showsUserMenu);
    setAnchorEl(event.currentTarget);
  }

  return (
    <AppBar component="nav" position='relative' className="bg-secondary">
      <Toolbar sx={{gap: '1rem'}}>
        <Logo color="primary" to="/app" icon />

        <span style={{marginLeft: 'auto'}}></span>

        {/* <LevelProgress user={props.user!} /> */}

        <Button 
          component={NavLink} 
          className={highlightsInviteButton ? "animate__animated animate__tada" : ""}
          to="/workspace/invitations"
          variant="outlined" 
          color="primary" 
          size="small">Invite Team</Button>

        <IconButton size="small"
                    onClick={toggleMenu}>
          <Avatar size={32} />
        </IconButton>
        <UserMenu {...props}
                  anchorEl={anchorEl} 
                  open={showsUserMenu}
                  onClose={toggleMenu} />
      </Toolbar>
    </AppBar>
  );
}

export default UserNav;