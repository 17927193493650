import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {} from '@mui/material';
import Api from "../../network/Api";
import User from "../../types/User";

type Sheet = {
  name: string,
  state: SheetState,
  created_by: User,
  approved_by: User,
  edited_by: Array<User>
}

enum SheetState {
  draft,
  review,
  approved
}

const EditSheet = () => {

  const { sheetId } = useParams();
  const [sheet, setSheet] = useState<Sheet>();

  useEffect(() => {
    Api.get(`/sheets/${sheetId}`).then(result => {
      setSheet(result);
    })
  }, []);

  return <>
    <h3>{sheet?.name}</h3>
  </>;
}

export default EditSheet;