import React, { useEffect, useState } from 'react';
import { Divider, Button, FormGroup, FormLabel, IconButton, FormControlLabel, Radio, Chip, RadioGroup, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemButton } from '@mui/material';
import Toolbox, { ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';
import Api from '../../network/Api';
import User, { Role } from '../../types/User';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { LoadingButton } from '@mui/lab';

const Members = (props: StartProps & ToolboxProps) => {

  const [users, setUsers] = useState<Array<User>>();
  const [editUser, shouldEdit] = useState<User>();

  useEffect(() => {
    if (typeof(editUser) !== "undefined") { return }
    Api.get("/workspace/users").then(setUsers)
  }, [editUser])

  return <> 
    <List disablePadding>
    { users?.map(user => { return <ListItem key={user.id} disablePadding>
      <ListItemText primary={user.display_name} secondary={atob(user.email)} />
      <ListItemSecondaryAction>
      <Chip size="small" color={Role[user.role] === Role.admin ? "primary" : Role[user.role] === Role.approver ? "default" : "secondary"} label={<strong>{Role[user.role]}</strong>} />
        <IconButton size="small" onClick={() => shouldEdit(user)} sx={{ml: 1}}><MoreHorizIcon color="primary" /></IconButton>
      </ListItemSecondaryAction>
    </ListItem> }) }
    </List>

    { editUser && <EditUserRole workspace={props.workspace} user={editUser} onClose={() => shouldEdit(undefined)} /> }
  </>
}

const EditUserRole = (props: StartProps & ToolboxProps) => {

  const [role, asRole] = useState<Role>();
  const [isUpdating, shouldUpdate] = useState(false);
  
  useEffect(() => {
    if (!isUpdating) { return }
    const roleKey = Object.keys(Role).find(key => Role[key] == role);
    Api.put(`/workspace/users/${props.user.id}`, {role: roleKey}).then(result => {
      shouldUpdate(false);
      props.onClose();
    });
  }, [isUpdating]);

  return <Toolbox maxWidth='sm' title="Change user role" onClose={props.onClose}><>
    <p>Select the account type <strong>{props.user.display_name}</strong> should have for <strong>{props.workspace.name}</strong>.</p>
    
    <FormLabel>Choose account type</FormLabel>
    <RadioGroup
      defaultValue={props.user.role}
      name="role"
      onChange={(e, value) => asRole(Role[value])}>
      { Object.keys(Role).map((key, index) => {
        return <FormControlLabel value={key} control={<Radio />} label={Object.values(Role)[index]} />
      })}
    </RadioGroup>

    <FormGroup row>
      <Button variant="text" color="error" sx={{marginRight: 'auto'}}>Remove member</Button>
      <Button variant="outlined" color="secondary" onClick={props.onClose}>Cancel</Button>
      <LoadingButton variant="outlined" color="primary" onClick={() => shouldUpdate(true)}>Save</LoadingButton>
  </FormGroup>
  </></Toolbox>
}

export default Members;