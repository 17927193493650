import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider, Button, FormGroup, TextField, MenuList, MenuItem, ListItemIcon, ListItemText, Container } from '@mui/material';
import { NavigationDrawer, ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';

import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupIcon from '@mui/icons-material/Group';
import Members from './Members';

enum ContentItem {
  details = "Workspace Details",
  company = "Company Details",
  members = "Members"
}

export const Settings = (props: StartProps & ToolboxProps) => {

  const { hash } = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState<ContentItem>(ContentItem.details);

  useEffect(() => {
    const content = hash ? ContentItem[hash.replace("#", "")] : ContentItem.details;
    setContent(content);
  }, [hash]);

  const renderMenuIcon = (item: ContentItem) => {
    switch (item) {
      case ContentItem.details:
        return <DisplaySettingsIcon />
      case ContentItem.company:
        return <BusinessCenterIcon />
      case ContentItem.members:
        return <GroupIcon />
    }
  }
  
  const renderCurrentContent = (item: ContentItem) => {
    switch (item) {
      case ContentItem.details:
        return <Details {...props} />
      case ContentItem.company:
        return <Company {...props} />
      case ContentItem.members:
        return <Members {...props} />
    }
  }
  
  return <NavigationDrawer menu={<MenuList disablePadding>
    { Object.values(ContentItem).map((item, index) => <MenuItem key={item} selected={content == item} onClick={() => navigate(`#${Object.keys(ContentItem)[index]}`)}>
      <ListItemIcon>{renderMenuIcon(item)}</ListItemIcon>
      <ListItemText>{item}</ListItemText></MenuItem>)}
    </MenuList>} currentContent={<Container maxWidth="md">{renderCurrentContent(content)}</Container>} 
  />;
};

const Details = (props: StartProps & ToolboxProps) => {
  return <>
    <FormGroup>
      <TextField name="name" required label="Workspace Name" defaultValue={props.workspace.name} variant="outlined" />
    </FormGroup>

    <FormGroup>
      <Button variant="text" color="error">Delete Workspace</Button>
    </FormGroup>
  </>;
};

const Company = (props: StartProps & ToolboxProps) => {
  return <>
    <FormGroup>
      <TextField name="industry" label="Industry" defaultValue={props.workspace.industry} variant="outlined" />
    </FormGroup>

    <FormGroup>
      <TextField name="description" label="Main Business Purpose" defaultValue={props.workspace.description} variant="outlined" />
    </FormGroup>

    <FormGroup row>
      <Button variant="outlined" color="secondary" onClick={props.onClose}>Cancel</Button>
      <Button variant="outlined" color="primary" type="submit">Save</Button>
    </FormGroup>
  </>;
};

export default Settings;