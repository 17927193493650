import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, FilledInput, Box, Container } from '@mui/material';
import Api from "../../../network/Api";
import SendIcon from '@mui/icons-material/Send';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from "@mui/lab";
import LessonsList from "./List";
import Lesson from "../../../types/Lessons";
import Importer, { ImportButtons } from "./Importer";
import { Accept } from "react-dropzone";

type NewLessonProps = {
  defaultValue?: Array<Lesson>,
  onStore: (lessons: Array<Lesson>) => void
}

const NewLesson = (props: NewLessonProps) => {

  const [input, setInput] = useState<string>("");
  const [lessons, setLessons] = useState<Array<Lesson>>(props.defaultValue ?? []);
  const [isSaving, shouldSave] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);
  const [importing, shouldImport] = useState<Accept>();

  const initialLoad = useRef(true);
  const scrollRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }
    props.onStore(lessons);
  }, [lessons]);

  useEffect(() => {
    if (input === "" || typeof(input) === "undefined") {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [input]);

  const addLesson = (input: string): Lesson | null => {
    if (typeof(input) === "undefined" || !input.replace(/\s/g, '').length) { return null }
    const newLesson: Lesson = { id: `${new Date().getTime()}_${Math.random()}`, learned: input, state: { isAnalyzed: false, isEdited: false } }
    setLessons(current => [...current, newLesson]);
    setInput("");
    return newLesson;
  }

  const updateLesson = (lesson: Lesson) => {
    setLessons(current => {
      return current.map(item => {
        if (item.id != lesson.id) { return item }
        return lesson;
      })
    })
  }

  const removeLesson = (lesson: Lesson) => {
    setLessons(current => {
      return current.filter(item => item.id != lesson.id);
    });
  }

  const addAndClassifyLessons = (lessons: Array<string>) => {
    let addedLessons = lessons.map(lesson => {
      return addLesson(lesson);
    });
    classify(addedLessons);
  }

  const classify = (newLessons: Array<Lesson|null>) => {
    setIsClassifying(true);
    Api.post("/labels", { lessons: newLessons }).then(result => {
      result.forEach(labels => {
        let existing = newLessons.find(lesson => lesson?.id == labels.id);
        updateLesson({...existing, ...labels, state: {...existing?.state, isAnalyzed: true}});
      });
      setIsClassifying(false);
    });
  }

  const onLessonsStored = () => {
    navigate("/app#completed");
  }

  useEffect(() => {
    if (!isSaving || lessons.length <= 0) { return }
    Api.post(`/lessons`, { lessons: lessons }).then(result => {
      if (result?.error) {
        shouldSave(false);
        return
      }
      props.onStore([]);
      onLessonsStored();
    })
  }, [isSaving]);

  return <Stack className="animate__animated animate__fadeIn" marginY={"auto"} flexGrow={lessons.length > 0 ? 1 : 0} width="100%">
    <Stack sx={{flex: lessons.length > 0 ? '1 1 0' : 1, overflowY: 'auto', py: 4, alignItems: 'center'}}>

      <Container maxWidth="md">
        <Stack spacing={2}>
          <LessonsList lessons={lessons} 
                        isAnalyzing={isClassifying}
                        editable={!isSaving}
                        onChange={updateLesson}
                        onRemove={removeLesson} />
          
          <Box ref={scrollRef} />
          
        </Stack>
      </Container>
    </Stack>

    <Container maxWidth="md">
      <Stack direction={{xs: "column", sm: "row"}} gap={2} sx={{alignItems: 'center'}}>
        <FilledInput placeholder="Enter your lessons learned one by one" 
                      fullWidth
                      sx={{flex: 1}}
                      autoComplete="off"
                      name="lesson"
                      startAdornment={<ImportButtons onImport={shouldImport} />}
                      endAdornment={<Button size="small" sx={{ml: '0.5rem'}} onClick={() => addAndClassifyLessons([input])}><SendIcon /></Button>}
                      value={input}
                      onChange={e => setInput(e.target.value)}
                      onKeyDown={e => e.key === "Enter" && addAndClassifyLessons([input])} />
        
        { lessons.length > 0 && <LoadingButton variant="outlined"
                                              loading={isSaving}
                                              color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => shouldSave(true)}>Store {lessons.length} items</LoadingButton> }
      </Stack>
    </Container>

    { importing && <Importer accept={importing} 
                             onCancel={() => shouldImport(undefined)}
                             onEdit={lessons => {
                              shouldImport(undefined);
                              addAndClassifyLessons(lessons);
                             }}
                             onUploaded={onLessonsStored} /> }
    
  </Stack>;
}

export default NewLesson;