import React, { useEffect, useRef } from "react";
import { Box, Tooltip, List } from '@mui/material';
import Lesson from "../../../types/Lessons";
import { SelectionProps } from "./Selection";
import LessonItem from "../Item";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

type LessonsListProps = {
  lessons: Array<Lesson>
}

const LessonsList = (props: LessonsListProps & SelectionProps) => {

  const scrollRef = useRef();

  useEffect(() => {
    props.onResult(props.lessons);
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.lessons]);

  return <>
    <h3>{props.lessons.length} items found.<br />You can initialize a SMART Workflow for each item. <Tooltip title="SMART Workflow"><HelpOutlineIcon fontSize="small" color="secondary" /></Tooltip></h3> 
    
    <List>{ props.lessons.map(lesson => <LessonItem {...lesson} />)}</List>

    <Box ref={scrollRef} />
 </>;
}

export default LessonsList;