import React from "react";
import { Container, Card } from "@mui/material";
import LoginForm from "./LoginForm";
import { AppProps } from "../App";
import OnboardingLayout from "../Onboarding/Layout";

const Login = (props: AppProps) => {
  return (
    <>
      {props.workspace ? (
        <OnboardingLayout title={`${props.workspace.name} is waiting for you`}>
          <LoginForm />
        </OnboardingLayout>
      ) : (
        <Container component={Card} maxWidth="xs">
          <LoginForm />
        </Container>
      )}
    </>
  );
};

export default Login;
