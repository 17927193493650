import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Divider, FormGroup, ListItemIcon, ListItemText, MenuItem, MenuList, TextField } from '@mui/material';
import { PasswordForm } from '../Shared/FormItems';
import { NavigationDrawer, ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';

import PersonIcon from '@mui/icons-material/Person';
import GppGoodIcon from '@mui/icons-material/GppGood';
import Diversity3Icon from '@mui/icons-material/Diversity3';

enum ContentItem {
  profile = "Profile Details",
  security = "Security & Privacy",
  workspace = "Workspace Membership"
}

export const Settings = (props: StartProps & ToolboxProps) => {

  const { hash } = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState<ContentItem>(ContentItem.profile);

  useEffect(() => {
    const content = hash ? ContentItem[hash.replace("#", "")] : ContentItem.profile;
    setContent(content);
  }, [hash]);

  const renderMenuIcon = (item: ContentItem) => {
    switch (item) {
      case ContentItem.profile:
        return <PersonIcon />
      case ContentItem.security:
        return <GppGoodIcon />
      case ContentItem.workspace:
        return <Diversity3Icon />
    }
  }
  
  const renderCurrentContent = (item: ContentItem) => {
    switch (item) {
      case ContentItem.profile:
        return <ProfileSettings {...props} />
      case ContentItem.security:
        return <SecuritySettings {...props} />
      case ContentItem.workspace:
        return <WorkspaceSettings {...props} />
    }
  }
  
  return <NavigationDrawer menu={<MenuList disablePadding>
    { Object.values(ContentItem).map((item, index) => <MenuItem key={item} selected={content == item} onClick={() => navigate(`#${Object.keys(ContentItem)[index]}`)}>
      <ListItemIcon>{renderMenuIcon(item)}</ListItemIcon>
      <ListItemText>{item}</ListItemText></MenuItem>)}
    </MenuList>} currentContent={<Container maxWidth="md">{renderCurrentContent(content)}</Container>}
  />;
};

const SecuritySettings = (props: StartProps & ToolboxProps) => {
  return <>
    <FormGroup>
      <p><strong>Change Password</strong></p>
    </FormGroup>

    <FormGroup>
      <TextField name="old_password" required label="Current Password" variant="outlined" type="password" />
    </FormGroup>

    <PasswordForm onChange={() => {}} />

    <FormGroup row>
      <Button variant="outlined" color="secondary" onClick={props.onClose}>Cancel</Button>
      <Button variant="outlined" color="primary" type="submit">Save</Button>
    </FormGroup>
  </>
}

const ProfileSettings = (props: StartProps & ToolboxProps) => {
  return <>
    <FormGroup>
      <p><strong>Account</strong></p>
    </FormGroup>

    <FormGroup>
      <TextField name="display_name" required label="Display Name" defaultValue={props.user.display_name} variant="outlined" />
    </FormGroup>

    <FormGroup>
      <TextField name="email" type="email" required label="Email" variant="outlined" defaultValue={atob(props.user.email)} />
    </FormGroup>

    <FormGroup>
      <p><strong>Company Role</strong></p>
    </FormGroup>

    <FormGroup>
      <TextField name="position_name" label="Current Position" defaultValue={props.user.position_name} variant="outlined" />
    </FormGroup>

    <FormGroup>
      <TextField name="position_description" label="Main Responsibility" defaultValue={props.user.position_description} variant="outlined" />
    </FormGroup>

    <FormGroup row>
      <Button variant="outlined" color="secondary" onClick={props.onClose}>Cancel</Button>
      <Button variant="outlined" color="primary" type="submit">Save</Button>
    </FormGroup>
  </>
}

const WorkspaceSettings = (props: StartProps & ToolboxProps) => {
  return <>
    <FormGroup>
      <Button variant="text" color="error">Leave Workspace</Button>
    </FormGroup>
  </>
}

export default Settings;