import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  Button,
  Container,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import Api from "../../network/Api";
import { WorkspaceSelectionProps } from "./Selection";
import FormUtils from "../../utils/FormUtils";
import OrDivider from "../Shared/OrDivider";
import JoinableList from "./JoinableList";
import Workspace from "../../types/Workspace";

const NewWorkspace = (props: WorkspaceSelectionProps) => {
  const [joinable, setJoinable] = useState<Array<Workspace> | undefined>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    Api.get(
      `/workspaces/new${queryParams.get("join_token") ? `?join_token=${queryParams.get("join_token")}` : ""}`,
    ).then((result: WorkspaceSelectionProps) => {
      setJoinable(result.joinable);
    });
  }, []);

  return (
    <Container component={Card} maxWidth="sm">
      <h2>Create a new Lessonice workspace</h2>
      <p style={{ margin: "1rem 0" }}>
        Lessonice gives your team a place to exchange lessons learned and
        generate valuable mitigation actions to prevent those mistakes in the
        future.
      </p>

      <form
        method="post"
        action="/workspaces"
        onSubmit={(e) => FormUtils.onFormSubmit(e)}
      >
        <FormGroup>
          <TextField
            name="name"
            label="Workspace name"
            variant="outlined"
            required
          />
        </FormGroup>

        <FormGroup>
          <Button type="submit" variant="outlined" color="primary">
            Create Workspace
          </Button>
        </FormGroup>

        <FormGroup>
          <p>
            <small>
              By continuing, you're agreeing to our Terms of Service and Privacy
              Policy.
            </small>
          </p>
        </FormGroup>
      </form>

      {joinable && joinable.length > 0 && (
        <section
          className="bg-dark"
          style={{ margin: "2rem -2rem -2rem", padding: "2rem" }}
        >
          <div
            className="bg-dark"
            style={{
              width: 80,
              height: 80,
              margin: "-4rem auto -1rem",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 40,
            }}
          >
            <OrDivider color="white" />
          </div>

          <Stack gap={2}>
            <h3 className="text-white">Accept invitation</h3>
            <JoinableList user={props.user} joinable={joinable} />
          </Stack>
        </section>
      )}
    </Container>
  );
};

export default NewWorkspace;
