export enum SentimentType {
  positive,
  negative,
  neutral
}

type Lesson = {
  id: string,
  learned: string,
  sentiment?: SentimentType,
  labels?: Array<string>,
  similar_to?: string,
  similar_content?: string,
  state: LessonState
}

type LessonState = {
  isAnalyzed: boolean,
  isEdited: boolean
}

export default Lesson;