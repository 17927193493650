import Lesson from "./Lessons"
import User from "./User"

export type TimelineAction = {
  id: string,
  timestamp: number,
  action_type: TimelineActionType,
  user: User,
}

export enum TimelineActionType {
  lesson_created,
  user_joined,
  sheet_created
}