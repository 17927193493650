import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FormGroup, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormUtils from "../../utils/FormUtils";
import GoogleSignIn from "./GoogleSignIn";
import OrDivider from "../Shared/OrDivider";

const LoginForm = () => {
  const [isLoading, shouldLoad] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  return (
    <>
      <FormGroup>
        <GoogleSignIn />
      </FormGroup>

      <FormGroup sx={{ margin: "1rem" }}>
        <OrDivider />
      </FormGroup>

      <form
        method="post"
        action="/verifications"
        onSubmit={(e) => {
          FormUtils.onFormSubmit(e);
          shouldLoad(true);
        }}
      >
        <FormGroup>
          <p>
            We suggest using the <strong>email address you use at work</strong>.
          </p>
        </FormGroup>

        <FormGroup>
          <TextField
            name="email"
            type="email"
            required
            label="Email"
            variant="outlined"
          />
        </FormGroup>

        <FormGroup>
          <input
            type="hidden"
            name="redirect_url"
            value={queryParams.get("redirect_url") ?? ""}
          />
          <LoadingButton
            variant="outlined"
            color="primary"
            type="submit"
            loading={isLoading}
          >
            Sign In
          </LoadingButton>
        </FormGroup>
      </form>
    </>
  );
};

export default LoginForm;
