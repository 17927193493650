import React, { useState, useEffect } from "react";
import { Container, Divider, List, ListItem, ListItemText, Paper, Stack } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import Api from "../../network/Api";
import dayjs from "dayjs";
import { TimelineAction, TimelineActionType } from "../../types/Timeline";
import { StartProps } from "../Lessons/Start";

const TimelineActions = (props: StartProps) => {

  const [history, setHistory] = useState<Array<TimelineAction>>([]);

  useEffect(() => {
    Api.get(`/timeline`).then(setHistory);
  }, []);

  const content = (item: TimelineAction) => {
    switch(TimelineActionType[item.action_type]) {
      case TimelineActionType.lesson_created:
        return <>
          <small><strong>{item.user.display_name}</strong> added a new lessons learned.</small>
        </>
      case TimelineActionType.user_joined:
        return <>
          <small><strong>{item.user.display_name}</strong> joined {props.workspace.name}.</small>
        </>
      case TimelineActionType.sheet_created:
        return <>
          <small><strong>{item.user.display_name}</strong> started working on a SMART sheet.</small>
        </>
      default: 
        return <small></small>
    }
  }

  return <>{ history.length > 0 && <Container maxWidth="xs">
    
    <Stack direction="row" spacing={1}>
      <h3 className="text-secondary">Team Activity</h3>
    </Stack>

    <List sx={{maxWidth: '25vw'}}>{ history.map(item => 
      <ListItem key={item.id} disablePadding>
        <ListItemText 
          primary={content(item)}
          secondary={<small className="text-secondary">{dayjs.unix(item.timestamp).format("ll")}</small>} />
      </ListItem>
    ) }
    </List>
  </Container> } </>;
}

export default TimelineActions;