import React from "react";
import { Alert, Box, Button, Card, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Lesson from "../../../types/Lessons";
import { Sentiment, Tagging } from "./Labeling";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

type LessonsListProps = {
  lessons: Array<Lesson>,
  isAnalyzing: boolean,
  editable: boolean,
  onChange: (lesson: Lesson) => void,
  onRemove: (lesson: Lesson) => void
}

const LessonsList = (props: LessonsListProps) => {
  return <>
    <h3>Enter your lessons learned, either one by one, or use the importer to bulk-add items. Hit the save button once you're finished adding.</h3>
    
    { props.lessons.length > 0 && <List>
      { props.lessons.map(lesson => {
        return <Card
                  component={ListItem}
                  className="animate__animated animate__fadeIn"
                  key={lesson.id}
                  sx={{mb: 2, alignItems: 'flex-start'}}
                >
          <ListItemIcon>
            { props.editable && 
              <IconButton onClick={() => props.onRemove(lesson)}>
                <DeleteOutlineIcon color="secondary" fontSize="small" />
              </IconButton>
            }
          </ListItemIcon>
          
          <ListItemText primary={lesson.learned}
                        secondary={<Stack spacing={1} sx={{mt: 1}}>
            { lesson.state?.isAnalyzed || !props.isAnalyzing ? <>
              <p><strong>Sentiment:</strong> {<Sentiment {...lesson} />}</p>
              <p><strong>Related topics:</strong></p>
              <Tagging lesson={lesson} onChange={lesson => props.onChange(lesson)} />

              { lesson.similar_to && !lesson.state?.isEdited && <Alert severity="warning">
                Is this similar to <strong>{lesson.similar_content}</strong>?
                <Stack direction="row">
                  <Button size="small" variant="text" startIcon={<CheckIcon />} onClick={() => props.onChange({...lesson, similar_content: undefined, state: {...lesson.state, isEdited: true} })}>Yes</Button>
                  <Button size="small" variant="text" startIcon={<CloseIcon />} onClick={() => props.onChange({...lesson, similar_to: undefined, similar_content: undefined, state: {...lesson.state, isEdited: true} })}>No</Button>
                </Stack>
              </Alert> }
            </> : <LinearProgress /> }
          </Stack>} />
        </Card>
      })}
    </List> }
 </>;
}

export default LessonsList;