import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container, Stack, Button, CircularProgress } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Lesson from "../../../types/Lessons";
import Api from "../../../network/Api";
import Search from "./Search";
import Trending from "./Trending";
import List from "./List";

export enum ManageType {
  recover = "#recover",
  search = "#search",
  context = "#context",
  trending = "#trending",
  trendingPositive = "#trending-positive",
  trendingNegative = "#trending-negative",
}

export type SelectionProps = {
  onResult: (lessons: Array<Lesson>) => void;
};

type AskProps = {
  recoverValue?: Array<string>;
  onStore: (lessons: Array<string>) => void;
};

const Ask = (props: AskProps) => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const initialLoad = useRef(true);

  const [storedIds, storeIds] = useState<Array<string>>(
    props.recoverValue ?? [],
  );
  const [lessons, setLessons] = useState<Array<Lesson>>();

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }
    props.onStore(storedIds);
  }, [storedIds]);

  useEffect(() => {
    if (hash !== "#recover") {
      return;
    }
    Api.get(`/lessons?ids=${storedIds.join(",")}`).then((result) => {
      if (result && result.length > 0) {
        setLessons(result);
      } else {
        props.onStore([]);
        navigate("/lessons");
      }
    });
  }, [hash]);

  const storeSearch = (lessons: Array<Lesson>) => {
    storeIds(lessons.map((lesson) => lesson.id));
  };

  const renderSelectedContent = () => {
    switch (hash as ManageType) {
      case ManageType.recover:
        return typeof lessons !== "undefined" ? (
          <List lessons={lessons} onResult={() => {}} />
        ) : (
          <CircularProgress />
        );
      case ManageType.search:
        return <Search onResult={storeSearch} />;
      case ManageType.trending:
      case ManageType.trendingPositive:
      case ManageType.trendingNegative:
        return <Trending onResult={storeSearch} />;
      case ManageType.context:
        return (
          <>
            Delivering topics for the role of a --select-role-- with specific
            fields in --add-more-details--
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack
      className="animate__animated animate__fadeIn"
      sx={{ overflowY: "auto", alignItems: "center", marginY: "auto" }}
      width="100%"
    >
      <Container maxWidth="md">
        <Stack spacing={3} sx={{ py: 4 }}>
          <h3 className={hash ? "fadedOut" : ""}>
            Choose which lessons learned you want to manage.
          </h3>

          <Stack
            className={hash ? "fadedOut" : ""}
            gap={2}
            direction={{ xs: "column", md: "row" }}
            sx={{ flexWrap: "wrap", pb: 2 }}
          >
            {props.recoverValue && props.recoverValue.length > 0 && (
              <Button
                variant="text"
                color="warning"
                startIcon={<RotateLeftIcon />}
                component={NavLink}
                to="/lessons#recover"
              >
                Recover last result
              </Button>
            )}

            <Button
              variant="outlined"
              className={
                (hash as ManageType) == ManageType.search ? "Mui-focused" : ""
              }
              color="secondary"
              startIcon={<SearchIcon />}
              component={NavLink}
              to={ManageType.search}
            >
              Search by topic
            </Button>

            <Button
              variant="outlined"
              className={
                (hash as ManageType) == ManageType.context ? "Mui-focused" : ""
              }
              color="secondary"
              startIcon={<AutoAwesomeIcon />}
              component={NavLink}
              to={ManageType.context}
            >
              Topics that affect me
            </Button>

            <Button
              variant="outlined"
              className={
                (hash as ManageType) == ManageType.trending ? "Mui-focused" : ""
              }
              color="secondary"
              startIcon={<TrendingUpIcon />}
              component={NavLink}
              to={ManageType.trending}
            >
              Trending topics
            </Button>
          </Stack>

          {renderSelectedContent()}
        </Stack>
      </Container>
    </Stack>
  );
};

export default Ask;
