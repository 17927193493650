import React, { useState } from 'react';
import { Menu, Stack, IconButton, LinearProgress, MenuItem } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import User from "../../types/User";

type LevelProps = {
  user: User
}

const LevelProgress = (props: LevelProps) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const toggleHelp = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
      <small className='text-secondary'>Free</small>
      
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={25.0}
        sx={{ width: "15vw" }} />

      <IconButton size="small"
                  color="secondary"
                  onClick={toggleHelp}><HelpIcon fontSize='small' /></IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <ul>
            <li>Gamification: für jede lessons learned 2 tokens und abfrage jeweils 1 token, durch accept von invites</li>
            <li>Progress bar info bzw. paketauswahl "wie kann ich mein gratis abo verlängern?" -> liste von actions</li>
            <li>Balken rechts oben: Level 1-3</li>
            <li>(Nur innerhalb des Testzeitraums): Jedes level bringt ein monat mehr gratis zeitraum</li>
            <li>Premium modelle: 1 month free immer, pro level + 1 monat</li>
            <li>Nach kostenlosem Zeitraum muss premium gekauft werden</li>
            <li>Wenn Premium: Gamification konzept: jedes level bringt abo rabatt</li>
            <li>Level muss gehalten werden, sonst fällt man wieder zurück "Achtung, du verlierst dein Level"</li>
            <li>Free / pro User oder Workspace / Enterprise -> level entfällt</li>
            <li>Premium abo: gedeckelt mit x abfragen -> wenn limit überschritten (threshold) -> entweder mehrmals drüber, dann muss paket hochgeschraubt werden, wenn einmalig, dann einmalig zahlen</li>
            <li>Enterprise internal gamification: Barometer (Zb du bist im internationalen vergleich gut/schlecht)</li>
            <li>Wenn innerhalb des ersten gratis zeitraum auf premium -> x% Rabatt</li>
          </ul>
        </MenuItem>
      </Menu>
    </Stack>);
}

export default LevelProgress;