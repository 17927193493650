type User = {
  id: string,
  email: string,
  domain?: string,
  display_name: string,
  role: string,
  position_name?: string,
  position_description?: string
}

export enum Role {
  member = "Regular Member",
  approver = "Process Approver",
  admin = "Workspace Admin"
}

export default User;