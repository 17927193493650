import React from "react";
import { TextField, Autocomplete, Chip, Stack } from '@mui/material';
import Lesson, { SentimentType } from "../../../types/Lessons";

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

export const Sentiment = (lesson: Lesson) => {

  const renderSentiment = (sentiment: SentimentType) => {
    switch (SentimentType[sentiment]) {
      case SentimentType.positive:
        return <SentimentSatisfiedAltIcon color="success" fontSize="small" />
      case SentimentType.negative:
        return <SentimentVeryDissatisfiedIcon color="warning" fontSize="small" />
      default:
        return <SentimentNeutralIcon color="secondary" fontSize="small" />
    }
  }

  return <>{ renderSentiment(lesson.sentiment) }</>
}

type TaggingProps = {
  lesson: Lesson,
  onChange: (lesson: Lesson) => void
}

export const Tagging = (props: TaggingProps) => {

  return <Autocomplete
            multiple
            options={[]}
            value={props.lesson.labels ?? []}
            freeSolo
            disableClearable
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip size="small" {...getTagProps({ index })} label={option} />
              ))
            }
            onChange={(e, value) => props.onChange({...props.lesson, labels: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={false}
                variant="standard"
                placeholder="Add topic..."
              />
            )}
            className="animate__animated animate__fadeIn"
          />;
}