export default class Api {

  static get(path: string) {
    return this.request(path, 'get', null);
  }

  static post(path: string, params: {}) {
    return this.request(path, 'post', params);
  }

  static put(path: string, params: {}) {
    return this.request(path, 'put', params);
  }

  static delete(path: string) {
    return this.request(path, 'delete', null);
  }

  static request(path: string, method: "get" | "post" | "put" | "delete", params: {} | null) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
    const options = { method: method, 
                      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'X-CSRF-Token': csrfToken },
                      body: params && JSON.stringify(params) };
    
    return fetch(path, options)
      .then((response) => {
        return response.json()
      })
      .catch(error => console.log('API error', error))
  }

  static upload(path: string, file: File, params: {} | null) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
    const formData  = new FormData();
      formData.append('file', file);
      if (params) {
        Object.entries(params).forEach(([key, values]) => formData.append(key, values));
      }
      const options = { method: 'post', 
                        headers: { 'Accept': 'application/json', 'X-CSRF-Token': csrfToken },
                        body: formData }
    
      return fetch(path, options)
        .then((response) => {
          return response.json()
        })
        .catch(error => console.log('API error', error))
  }
}