import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Stack } from '@mui/material';
import Api from "../../../network/Api";
import Lesson from "../../../types/Lessons";
import { ManageType, SelectionProps } from "./Selection";
import List from "./List";

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import { LoadingButton } from "@mui/lab";

const Trending = (props: SelectionProps) => {

  const [trending, setTrending] = useState<Array<Lesson>>();

  const { hash } = useLocation();

  useEffect(() => {
    console.log("CHanging to", hash)
    switch(hash as ManageType) {
      case ManageType.trendingPositive:
        Api.get(`/lessons/trending?sentiment=positive`).then(setTrending);
        break;
      case ManageType.trendingNegative:
        Api.get(`/lessons/trending?sentiment=negative`).then(setTrending);
        break;
      default:
        break
    }
  }, [hash]);

  return <>
    <h3 className={trending && trending.length > 0 ? "fadedOut" : "animate__animated animate__fadeIn"}>Choose which trending items you want to retrieve.</h3>

    <Stack className={trending && trending.length > 0 ? "fadedOut" : "animate__animated animate__fadeIn animate__delay-2s"} gap={2} direction={{xs: "column", md: "row"}} sx={{pb: 2}}>
      <LoadingButton variant="outlined" 
              className={hash as ManageType == ManageType.trendingNegative ? "Mui-focused" : ""}
              color="secondary"
              component={NavLink} 
              startIcon={<SentimentVeryDissatisfiedIcon />}
              to={ManageType.trendingNegative}>Negative trending</LoadingButton>

      <LoadingButton variant="outlined" 
              className={hash as ManageType == ManageType.trendingPositive ? "Mui-focused" : ""}
              color="secondary"
              component={NavLink} 
              startIcon={<SentimentSatisfiedAltIcon />}
              to={ManageType.trendingPositive}>Positive trending</LoadingButton>
    </Stack>

    { trending && (trending.length > 0 ? <List lessons={trending} onResult={props.onResult} /> : <p>No results</p>) }
  </>;
}

export default Trending;