import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, FormLabel, ListItem, ListItemText, Stack } from '@mui/material';
import Lesson from "../../types/Lessons";
import Api from "../../network/Api";

import ChatIcon from '@mui/icons-material/Chat';
import FactCheckIcon from '@mui/icons-material/FactCheck';

const LessonItem = (lesson: Lesson) => {

  const navigate = useNavigate();

  const createSheet = () => {
    Api.post(`/lessons/${lesson.id}/sheets`, {}).then(result => {
      if (!result?.id) { return }
      navigate(`/sheets/${result.id}`);
    })
  }

  return <ListItem
      className="animate__animated animate__fadeIn"
      key={lesson.id}
      disablePadding
    >
    <ListItemText 
      primary={lesson.learned}
      secondary={<Stack direction="row" spacing={1}>
        <Button size="small" startIcon={<ChatIcon />}>10 comments</Button>
        <Button size="small" startIcon={<FactCheckIcon />} onClick={createSheet}>SMART Sheet</Button>
      </Stack>} />
  </ListItem>
}

export default LessonItem;