import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import GoogleIcon from 'images/g-logo.png';

const GoogleSignIn = () => {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  return (
    <Button 
      href={`/login/google${queryParams.get('redirect_url') ? `?redirect_url=${queryParams.get('redirect_url')}` : ''}`}
      variant="outlined" 
      color="secondary"  
      startIcon={<img src={GoogleIcon} width="22px" height="22px" />}>Sign in with Google</Button>
  );
}

export default GoogleSignIn;