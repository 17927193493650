import React, { useState } from 'react';
import UserNav from '../Shared/UserNav';
import { Box, Snackbar } from '@mui/material';
import InfoLayout from './InfoLayout';
import ConsentScripts from '../../utils/ConsentScripts';
import { LayoutProps, ViewProps } from '../../types/View';
import { AppProps } from '../App';

const Layout = (props: AppProps & LayoutProps & ViewProps) => {

  const [flash, setFlash] = useState(props.flash);

  return (<>

    <ConsentScripts />

    { flash?.filter((flash) => flash.severity == 'event')?.forEach((event) => {
//      gtag('event', event.message.name);
    })}

    { flash?.filter((flash) => flash.severity != 'event').map((flash) => {return <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={true}
      onClose={() => setFlash([])}
      message={flash.message}
      key={flash.message}
      autoHideDuration={10000}
    />})}

    { props.variant ? props.variant === "info" ? <InfoLayout {...props} /> :
    <Box display="flex" 
          flexDirection="column"
          height="100vh">
      <UserNav {...props} />
      <Box display="flex" 
           flexDirection="column"
           flexGrow={1}
           overflow="hidden">
        {props.children}
      </Box>
    </Box> : props.children }
  </>)
};

export default Layout;