import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, Chip, TextField, FormGroup, Button, Menu, MenuItem, Stack, Tooltip, FormLabel } from '@mui/material';
import { ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';
import Api from '../../network/Api';
import moment from 'moment';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';
import { LoadingButton } from '@mui/lab';
import { Role } from '../../types/User';

type Link = {
  url: string,
  expires_at: number
}

const Invite = (props: StartProps & ToolboxProps) => {

  const [showsOptions, showOptions] = useState<boolean>(false);
  const [emails, setEmails] = useState<Array<string>>();
  const [role, asRole] = useState<Role>(Role.member);
  const [link, setLink] = useState<Link>();
  const [isSending, shouldSend] = useState<boolean>(false);
  const [isCopying, shouldCopy] = useState<boolean>(false);
  const anchorEl = useRef();

  useEffect(() => {
    if (!isSending) { return }
    const roleKey = Object.keys(Role).find(key => Role[key] == role);
    Api.post("/workspace/invitations", {role: roleKey, emails: emails}).then(result => {
      window.location.replace(result.url)
    })
  }, [isSending]);

  const copyLink = () => {
    shouldCopy(false);
    if (typeof(link?.url) === "undefined") { return }
    navigator.clipboard.writeText(link.url);
  }

  useEffect(() => {
    if (!isCopying) { return }
    if (typeof(link) !== "undefined") {
      copyLink();
      return
    }
    Api.post("/workspace/invitations", {linkonly: true}).then(setLink);
  }, [isCopying]);

  useEffect(() => {
    copyLink();
  }, [link]);

  return <>
    <FormGroup>
      <Stack spacing={1}>
      <FormLabel>Send to</FormLabel>
        
      <Autocomplete
          multiple
          options={[]}
          value={emails}
          freeSolo
          disableClearable
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip {...getTagProps({ index })} label={<strong>{option}</strong>} />
            ))
          }
          onChange={(e, value) => setEmails(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              type="email"
              placeholder="Add one or more email addresses, seperated by enter key"
            />
          )}
          className="animate__animated animate__fadeIn"
        />
        
      <FormLabel>Invite as</FormLabel>
      
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined" 
          color="secondary"
          ref={anchorEl}
          onClick={() => showOptions(true)}
          endIcon={<ExpandMoreIcon />}>{role}</Button>

        <LoadingButton
          variant="outlined" 
          color="primary"
          startIcon={<SendIcon />}
          loading={isSending}
          onClick={() => shouldSend(true)}>Send</LoadingButton>
      </Stack>

      <Menu
        anchorEl={anchorEl.current}
        open={showsOptions}
        onClose={() => showOptions(false)}
      >
        {Object.values(Role).map(role => <MenuItem onClick={() => {
          asRole(role);
          showOptions(false);
        }}>{role}</MenuItem>)}
      </Menu>
      </Stack>
    </FormGroup>

    <FormGroup>
      <Stack direction="row" spacing={1}>
        <FormLabel sx={{flex: 3}}>You can also share an expiring link and assign roles later in the workspace settings.</FormLabel>
        <Tooltip 
          title={`Link copied - expires ${moment.unix(link?.expires_at).fromNow()}.`} 
          open={typeof(link) !== "undefined"}
          sx={{flex: 1}}>
          <LoadingButton
            variant='text'
            startIcon={<ShareIcon />}
            onClick={() => shouldCopy(true)}
            loading={isCopying}>Copy link</LoadingButton>
        </Tooltip>
      </Stack>
    </FormGroup>
  </>
}

export default Invite;