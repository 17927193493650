import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Stack, Fab, Paper } from '@mui/material';
import OnboardingSteps from "../../Onboarding/OnboardingSteps";
import { OnboardingStep } from "../../../types/Onboarding";
import Api from "../../../network/Api";

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const LessonsLearned = () => {

  return <>
    <Grid container maxWidth="lg" spacing={4}>
      
      <Grid item xs={12} md={4}>
        <Paper>
          <Stack direction="row" sx={{placeContent: 'space-between'}}>
            <h3>Lessons Learned</h3>
            <Fab color="primary" disabled><TipsAndUpdatesIcon /></Fab>
          </Stack>
          <h2>1234</h2>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper>
          <h3>Aktivität</h3>
          <div className='area-chart'></div>
        </Paper>
      </Grid>
    </Grid>
  </>;
}

export default LessonsLearned;